// src/components/ServiceList.tsx
import React from 'react';
import ServiceCard from '../projectCard/project.card.component';
import { Grid } from '@mui/material';
import ProjectCard, { Project } from '../projectCard/project.card.component';
import { Home, Build, Code, DesignServices, Support, IntegrationInstructions, SettingsInputComponent, School, AutoAwesome, MilitaryTech } from '@mui/icons-material';

const projects: Project[] = [
  { link: '/services/development', icon: < MilitaryTech  sx={{fontSize: 'inherit'}} />, title: "Kourtis", desc: "Plateforme de gestion d'intermédiaires d'assurance" },
  { link: '/services/training', icon: < MilitaryTech  sx={{fontSize: 'inherit'}} />, title: 'Market ', desc:"Plateforme de gestion de vente et stocks" },
  { link: '/services/integration', icon: < MilitaryTech  sx={{fontSize: 'inherit'}} />, title: 'Kampux ', desc: "Plateforme de gestion d’établissements scolaires" },
  { link: '/services/consulting', icon: < MilitaryTech  sx={{fontSize: 'inherit'}} />, title: "Shine ", desc: "Plateforme de gestion d’institut de beauté et spa"},
  { link: '/services/consulting', icon: < MilitaryTech  sx={{fontSize: 'inherit'}} />, title: "Nyxis ", desc: "Plateforme de gestion de communication spécialisée dans l'intégration et des échanges via WhatsApp pour les entreprises"},
  { link: '/services/consulting', icon: < MilitaryTech  sx={{fontSize: 'inherit'}} />, title: "Pulse ", desc: "Plateforme de gestion d'SMS et de planification de rendez-vous"},
  
 
];

const ProjectList: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {projects.map((project, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} sx={{marginBottom: "2em",}}>
          <ProjectCard project={project} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectList;
