// src/components/ServiceList.tsx
import React from 'react';
import ServiceCard from '../ValueCard/value.card.component';
import { Grid } from '@mui/material';
import ValueCard, { Value } from '../ValueCard/value.card.component';
import { Home, Build, Code, DesignServices, Support, IntegrationInstructions, SettingsInputComponent, School, AutoAwesome, Balance, Handshake, MilitaryTech, Groups } from '@mui/icons-material';

const values: Value[] = [
  { title: "L’Ethique", icon: <Balance sx={{fontSize: 'inherit'}} />, description: "Des standards éthiques rigoureux guident chaque interaction, garantissant transparence, honnêteté et responsabilité à chaque étape de la collaboration." },
  { title: 'Engagement', icon: <Handshake sx={{fontSize: 'inherit'}} />, description: "Nous honorons pleinement nos engagements envers nos clients en alliant écoute attentive et compréhension approfondie de leurs besoins, pour offrir des solutions optimales en respectant nos promesses." },
  { title: 'Excellence', icon: <MilitaryTech sx={{fontSize: 'inherit'}} />, description: "Nos domaines d’intervention sont définis par une recherche perpétuelle de l’excellence et un engagement déterminé à créer des services et des solutions à valeur ajoutée élevée" },
  { title: "Travail en equipe", icon: <Groups sx={{fontSize: 'inherit'}} />, description: "Chez Univsoft, nous privilégions une culture de collaboration où le travail en équipe est central. Nous adoptons une approche managériale basée sur le soutien, l’encouragement et la responsabilisation, plutôt que sur le contrôle et la sanction" },
 
];

const ValueList: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {values.map((value, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}  sx={{marginBottom: "2em",}}>
          <ValueCard value={value} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ValueList;
