// src/components/ServiceCard.tsx
import React, { ReactElement } from 'react';
import { Card, CardContent, CardActions, Typography, Button, IconButton, Icon, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';



export interface Service {
    icon: ReactElement;
    title: string;
    description: string;
    link: any;
}


interface ServiceCardProps {
    service: Service;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
    

    return (
        <Card sx={{ maxWidth: 345,
            margin: 'auto',
            textAlign: 'center',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            ':hover': {
              background: '#3364ae',
              color: 'white',
              cursor: 'pointer',
              transition: 'background-color 1s ease'
            }
        }}>
            <CardContent>
                <Box sx={{
                    padding: '2em 0.5em',
                    // border: '1px solid red',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '1em'

                }}>
                    <Icon sx={{
                        fontSize: '4.5em'
                    }}>
                        {service.icon}
                    </Icon>
                </Box>
                <Box sx={{
                    padding: '0 1em',

                }}>
                    <Typography sx={{ fontSize: 24, fontWeight: '900', textTransform: 'capitalize' }} variant="h5" component="div">
                        {service.title}
                    </Typography>
                    <Typography sx={{ marginTop: 2, marginBottom: 2, textAlign: 'justify' }} variant="body1" >
                        {service.description}
                    </Typography>
                </Box>
            </CardContent>
            <CardActions>
                {/* <IconButton
                    // size="large"
                    sx={{ fontSize: '1.5em', width: '64px', height: '64px', margin: '0 auto', color: 'black' }} // Adjust the size here
                >
                <Link to={service.link} style={{color: 'inherit'}}>
                    <ArrowForward sx={{ fontSize: 'inherit' }} />
                </Link>
                </IconButton> */}
            </CardActions>
        </Card>
    );
};

export default ServiceCard;
