import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const About = () => {
    return (
        <Box sx={{
            height: '100vh',
            width: '100%', // Ensure the Box takes the full width
            display: 'flex',
            // flexDirection: {lg: 'row', sm: 'column-reverse', xs: 'column-reverse'},
            
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid red'
        }}>
            <Box flex={1} padding={2} width="100%" display='flex' flexDirection='column' gap={4} >
                <Typography variant="h2" sx={{ textAlign: 'left', lineHeight: '1em', fontWeight: '900', width: '80%',  color: 'white', fontSize: {
               sm: "2em",
              xs: "2em"
            } }}>A propos de nous</Typography>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'left', color: 'white', fontSize: {lg: '1.2em'} }}>
                    Univsoft est votre partenaire stratégique pour une transformation numérique
                    réussie. Expert dans la digitalisation des organisations, Univsoft propose des
                    solutions innovantes, accessibles et particulièrement efficaces pour répondre à
                    vos défis actuels. Notre expertise repose sur une approche personnalisée et
                    évolutive, garantissant une mise en œuvre rapide et précise des projets.
                </Typography>

            </Box>
            <Box flex={1} sx={{
                display: {sm: 'none', xs: 'none', lg: 'inline'}
            }}>
                <img src="/assets/images/slider_image-1.png" alt="" style={{ maxWidth: '100%', maxHeight: '65vh',  }} />
            </Box>
        </Box>
    )
}

export default About