// src/components/ServiceCard.tsx
import React, { ReactElement } from 'react';
import { Card, CardContent, CardActions, Typography, Button, IconButton, Icon, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';


export interface Project {
    icon: ReactElement;
    title: string;
    desc: string;
    link: any;
}


interface ProjectCardProps {
    project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {


    return (
        <Card sx={{
            maxWidth: 345,
            margin: 'auto',
            textAlign: 'center',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            ':hover': {
              background: '#3364ae',
              color: 'white',
              cursor: 'pointer',
              transition: 'background-color 1s ease'
            }

        }}>
            <CardContent>
            <Box sx={{
                padding: '2em 0.5em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '1em'
              }}>
                <Icon sx={{ fontSize: '4.5em' }}>
                  {project.icon}
                </Icon>
              </Box>
                <Box sx={{ padding: '0 1em' }}>
                    <Typography sx={{ fontSize: 24, fontWeight: '900', textTransform: 'capitalize' }} variant="h5" component="div">
                        {project.title}
                    </Typography>
                    <Typography sx={{ marginTop: 2, marginBottom: 2, textAlign: 'center' }} variant="body1">
                        {project.desc}
                    </Typography>
                </Box>
            </CardContent>
            {/* <CardActions>
                <IconButton
                    // size="large"
                    sx={{ fontSize: '1.5em', width: '64px', height: '64px', margin: '0 auto', color: 'black' }} // Adjust the size here
                >
                    <Link to={project.link} style={{ color: 'inherit' }}>
                        <ArrowForward sx={{ fontSize: 'inherit' }} />
                    </Link>
                </IconButton>
            </CardActions> */}
        </Card>
    );
};

export default ProjectCard;
