// src/components/ServiceCard.tsx
import React, { ReactElement } from 'react';
import { Card, CardContent, CardActions, Typography, Button, IconButton, Icon, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';



export interface Value {
    icon: ReactElement;
    title: string;
    description: string;

}


interface ValueCardProps {
    value: Value;
}

const ValueCard: React.FC<ValueCardProps> = ({ value }) => {


    return (
        // <Card sx={{
        //     maxWidth: 345, margin: 'auto', textAlign: 'center', borderRadius: '15px',
        //     ':hover': {
        //         background: '#3364ae',
        //         color: 'white',
        //         cursor: 'pointer',
        //         transition: 'background-color 1s ease'
        //     }

        // }}>
        //     <CardContent>
        //     <Box sx={{
        //             padding: '2em 0.5em',
        //             // border: '1px solid red',
        //             display: 'flex',
        //             alignItems: 'center',
        //             justifyContent: 'center',
        //             marginBottom: '1em'

        //         }}>
        //             <Icon sx={{
        //                 fontSize: '4.5em',
        //             }}>
        //                 {value.icon}
        //             </Icon>
        //         </Box>

        //         <Box sx={{
        //             padding: '0 1em',
        //             display: 'flex',
        //             flexDirection: 'column',
        //             gap: 5
        //         }}>
        //             <Typography sx={{ fontSize: 24, fontWeight: '900', textTransform: 'capitalize', padding: '1em 0 0 0' }} variant="h5" component="div">
        //                 {value.title}
        //             </Typography>
        //             <Typography sx={{ marginTop: 2, marginBottom: 2, textAlign: 'justify' }} variant="body1" >
        //                 {value.description}
        //             </Typography>
        //         </Box>
        //     </CardContent>
        // </Card>

        <Card sx={{
            maxWidth: 345,
            margin: 'auto',
            textAlign: 'center',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            ':hover': {
              background: '#3364ae',
              color: 'white',
              cursor: 'pointer',
              transition: 'background-color 1s ease'
            }
          }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Box sx={{
                padding: '0 0.5em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // marginBottom: '1em'
              }}>
                <Icon sx={{ fontSize: '4.5em' }}>
                  {value.icon}
                </Icon>
              </Box>
        
              <Box sx={{
                padding: '0 1em',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                flexGrow: 1
              }}>
                <Typography sx={{ fontSize: 24, fontWeight: '900', textTransform: 'capitalize', padding: '1em 0 0 0' }} variant="h5" component="div">
                  {value.title}
                </Typography>
                <Typography sx={{ marginTop: 2, marginBottom: 2, textAlign: 'justify' }} variant="body1">
                  {value.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
    );
};

export default ValueCard;
