import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from '../../features/about/about.feature';
import Blog from '../../features/blog/blog';
import Home from '../../features/home/home.feature';

import Services from '../../features/services/services';
import MainLayout from '../../layout/main.layout';


const PublicRoutes = () => (
  <Routes>
  <Route element={<MainLayout />}>
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/services" element={<Services />} />
    <Route path="/blog" element={<Blog />} />
  </Route>
</Routes>
);

export default PublicRoutes;
