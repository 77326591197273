import { Box, Typography } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../ui/Footer/footer.ui';
import Navbar from '../ui/Navbar/navbar.ui';
import "./main.layout.css";

const MainLayout: React.FC = () => (
  <Box sx={{ position: "relative", backgroundColor: 'white', maxWidth: '100%' }}>
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1000 }}>
      {/* Your header content */}
      <Navbar />
    </Box>

    {/* main content */}
    <Box sx={{
      minHeight: "100vh",
      maxWidth: '100%',
      // paddingBottom: '50px' // Space for the footer
    }}>
      <Outlet /> {/* This will render the child routes */}
    </Box>

    {/* footer */}
    <Box maxWidth={"100%"}>
      <Footer />
    </Box>
  </Box>
);

export default MainLayout;
