// The themes folder is where you define and manage your application’s theme settings.
// This includes colors, typography, spacing, breakpoints, shadows, and any other design 
// tokens that you want to standardize across your application. The theme settings are typically 
// used to ensure a consistent look and feel throughout your app.

import { BreakpointsOptions, createTheme } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Shadows } from '@mui/material/styles/shadows';
import { ComponentsOverrides, Theme } from '@mui/material/styles';
import { TransitionsOptions } from '@mui/material/styles/createTransitions';

// Define your color palette
const palette: PaletteOptions = {
    primary: {
        light: '#ffffff', // Light shade of white
        main: '#ffffff',  // Main shade of white
        dark: '#f0f0f0',  // Dark shade of white (optional)
        contrastText: '#1976d2', // Text color for primary buttons
    },
    secondary: {
        light: '#e1bee7', // Light shade of purple
        main: '#9c27b0',  // Main shade of purple
        dark: '#7b1fa2',  // Dark shade of purple
        contrastText: '#ffffff', // Text color for secondary buttons
    },
    background: {
        default: '#ffffff',
    },
    // Add more color customizations
};

// Customize your typography settings
const typography: TypographyOptions = {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
        fontWeight: 900, // Extra Bold
        fontSize: '3rem',
    },
    h2: {
        fontWeight: 700, // Bold
        fontSize: '2.5rem',
    },
    h3: {
        fontWeight: 600, // Semi Bold
        fontSize: '2rem',
    },
    h4: {
        fontWeight: 500, // Medium
        fontSize: '1.75rem',
    },
    h5: {
        fontWeight: 400, // Normal
        fontSize: '1.5rem',
    },
    h6: {
        fontWeight: 300, // Light
        fontSize: '1.25rem',
    },
    subtitle1: {
        fontWeight: 400, // Normal
        fontSize: '1rem',
    },
    subtitle2: {
        fontWeight: 500, // Medium
        fontSize: '0.875rem',
    },
    body1: {
        fontWeight: 400, // Normal
        fontSize: '1rem',
    },
    body2: {
        fontWeight: 400, // Normal
        fontSize: '0.875rem',
    },
    button: {
        fontWeight: 500, // Medium
        fontSize: '0.875rem',
        textTransform: 'uppercase',
    },
    caption: {
        fontWeight: 400, // Normal
        fontSize: '0.75rem',
    },
};

// Define custom spacing values
const spacing = 8; // Example: 8px spacing unit


// Customize breakpoints for responsive design
const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};



// Define custom transition properties
const transitions: TransitionsOptions = {
    duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
    },
};

// Combine all customizations into a single theme object
const theme = createTheme({
    palette,
    typography,
    spacing,
    breakpoints,
    transitions,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Disable uppercase transformation
                },
            },
        },
        // MuiAppBar: {
        //     styleOverrides: {
        //         root: {
        //             // boxShadow: 'none', // Remove shadow from AppBar
        //         },
        //     },
        // },
    },
});

export default theme;
