import React from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import PublicRoutes from './publicRoutes';
import PrivateRoutes from './privateRoutes';

const AppRoute = () => (
  <Router>
      <PublicRoutes />
      {/* <PrivateRoutes /> */}
  </Router>
);

export default AppRoute;
