import logo from './logo.svg';
import './App.css';
import React from 'react';
import ErrorBoundary from './core/errors/ErrorBoundary';
import AppRoute from './core/routes';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './themes/theme';


function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <div className="App">
          <AppRoute />
        </div>
      </ThemeProvider>
    </ErrorBoundary>

  );
}

export default App;
